<template>
     <div class="page-content">
        <div  class="row">
             <pre-video src="https://cdn.joeltankard.com/website/projects/lighthouse/1-section.mp4" />
            <div>
                <h2>How it started</h2>
                <p>Sigma Prime had been developing Lighthouse, an open-source Ethereum client written in Rust. Their goal was to present it at an Ethereum conference in Amsterdam. However, the project faced a significant hurdle: its website was poorly functioning and needed urgent redesign.</p>
                                <p>The project had already cycled through two developers, but the website was still far from ready. It suffered from performance issues like freezing, crashing, and lack of responsiveness, making it a challenging task for any developer to take on.</p>

            </div>
        </div>


        <div class="row row-reverse">
             <pre-video src="https://cdn.joeltankard.com/website/projects/lighthouse/2-section.mp4" />
              

            <div>
                <h2>The catch</h2>
                <p>Amidst these challenges, Sigma Prime approached me to rescue the project. The challenge was immense, considering the two-week deadline and my existing commitments to a full-time job. After evaluating the situation, I decided to rebuild the website from scratch, as modifying the existing setup would be too time-consuming..</p>
            </div>
        </div>


            <div class="row">
                   <pre-video src="https://cdn.joeltankard.com/website/projects/lighthouse/3-section.mp4" />
    

                <div>
                    <h2>Leaning into CSS</h2>
                    <p>My strategy focused on using built-in browser capabilities, like CSS snap scroll and CSS transform animations, avoiding the overuse of JavaScript. This approach was crucial for meeting the tight deadline and ensuring the website's smooth performance.</p>
                </div>
            </div>

        
                 <div class="row row-reverse">
                     
                         <pre-video src="https://cdn.joeltankard.com/website/projects/lighthouse/4-section.mp4" />

                    <div>
                        <h2>60fps</h2>
                        <p>The effort paid off. The new website ran smoothly at 60fps on standard computers, perfectly aligning with the designer's vision.  This project stands out in my career as it significantly tested and expanded my technical skills in a very short time.</p>
                    </div>
                </div>

        <div class="outcome">
                <h2>Outcome</h2>
                <div class="stats">
                    <div>
                        <h3>Life span</h3>
                        <h4>2+ years</h4>
                    </div>
                    <div>
                        <h3>Staus</h3>
                        <h4>Success</h4>
                    </div>
                </div>
                <div>
                    <h3>Summary</h3>
                    <p>The project concluded with remarkable success. The new Lighthouse website was well-received at the Ethereum conference, showcasing Sigma Prime's innovation effectively. Both Sigma Prime and Empire, the design team, were extremely pleased with the outcome. Their satisfaction was evident when they extended a job offer to me in recognition of my efforts. Although I chose to stay with Always Here, this gesture was a significant acknowledgment of my skill and dedication. Reflecting on the experience, this project stands out as one of my favorites. It pushed my abilities to the limit, rapidly expanding my expertise in web development under tight deadlines.</p>
                </div>
        </div>
     </div>
</template>


<script>
import PreVideo from '@/components/PreVideo';

export default {
    name: 'Lighthouse',
    components: {
        PreVideo
    }
}
</script>